import { Scene } from 'phaser';
import Phaser from 'phaser';

let baseURL = 'https://static.appetitecreative.com/resources/connectedpackage/';

let gameOptions = {
    platformStartSpeed: 250,
    spawnRange: [175, 300],
    platformSizeRange: [50, 450],
    kinderStartSpeed: 250,
    kinderSpawnRange: [0, 5],
    kinderVerticalRange: [0.4, 0.5, 0.6, 0.7],
    playerGravity: 900,
    jumpForce: 400,
    playerStartPosition: 100,
    jumps: 2
}

let game = { 
    config: {
        width: window.innerWidth,
        height: window.innerHeight,
    }
}

export default class PlayScene extends Scene {

    constructor(){
        super({ key: 'PlayScene' });   
    }
    
    preload(){

        /* i18n */
        this.lang = this.game.registry.get('lang');
        this.i18n = {
            en: {
                loading: 'LOADING...',
                ohno: 'Oh no!'
            },
            ru: {
                loading: 'ЗАГРУЗКА...',
                ohno: 'О Нет!'
            },
            de: {
                loading: 'Laden...',
                ohno: 'Oh nein!'
            },
        };

        this.load.setBaseURL(baseURL);

        this.load.image("platform", "platform@2x.png");
        this.load.image("itemA", "item-a@2x.png");
        this.load.image("itemB", "item-b@2x.png");
        this.load.image("itemC", "item-c@2x.png");
        this.load.image("player", "character.jump@2x.png");
        this.load.image("playerA", "character.moveA@2x.png");
        this.load.image("playerB", "character.moveB@2x.png");
        this.load.image("playerJump", "character.jump@2x.png");
    }

    create(obj){

        this.resources = 0;
        this.timer = 0;
        this.lives = obj.lives || 3;
        this.score = obj.score || 0;
        this.playerTexture = 'playerA';
        this.scored = false;

        this.xmasItemsList = ['itemA','itemB','itemC'];

        /*************
        Platforms
        **************/
 
        // group with all active platforms.
        this.platformGroup = this.add.group({
            // once a platform is removed, it's added to the pool
            removeCallback: function(platform){
                platform.scene.platformPool.add(platform)
            }
        }); 
        this.platformPool = this.add.group({
            // once a platform is removed from the pool, it's added to the active platforms group
            removeCallback: function(platform){
                platform.scene.platformGroup.add(platform)
            }
        });


        /*************
        Kinders
        **************/
        this.kinderGroup = this.add.group({
            removeCallback: function(platform){
                platform.scene.kinderPool.add(platform)
            }
        });
        this.kinderPool = this.add.group({
            removeCallback: function(platform){
                platform.scene.kinderGroup.add(platform)
            }
        });

 
        // number of consecutive jumps made by the player
        this.playerJumps = 0;
 
        // adding a platform to the game, the arguments are platform width and x position
        this.addPlatform(game.config.width, game.config.width);
        this.addKinder(game.config.width);
 
        // adding the player
        this.player = this.physics.add.sprite(gameOptions.playerStartPosition, game.config.height / 2, "player");
        this.player.setScale(0.25)
        this.player.setGravityY(gameOptions.playerGravity);
        this.player.setOffset(0,0);
 
        // setting collisions between the player and the platform group
        this.physics.add.collider(this.player, this.platformGroup);
        
        // setting collisions between the player and the kinders group
        this.physics.add.overlap(this.player, this.kinderGroup, this.killKinder, null, this);

        // checking for input
        this.input.on("pointerdown", this.jump, this);
    }

    killKinder(player, xmasItem){
        xmasItem.visible = false
        this.kinderPool.remove(xmasItem)

        if(this.scored == false){
            this.score = this.score + 1
            this.registry.events.emit('update-score', { lives: this.lives, score: this.score, msg: '' });
            this.scored = true;
            setTimeout(() => {
                this.scored = false;
            }, 500);
        }
    }
 
    // platform are added from the pool or created on the fly
    addPlatform(platformWidth, posX){
        let platform;
        if(this.platformPool.getLength()){
            platform = this.platformPool.getFirst();
            platform.x = posX;
            platform.active = true;
            platform.visible = true;
            
            if(this.score > 3) platform.setVelocityX((gameOptions.platformStartSpeed + 100) * -1);
            if(this.score > 7) platform.setVelocityX((gameOptions.platformStartSpeed + 200) * -1);
            if(this.score > 12) platform.setVelocityX((gameOptions.platformStartSpeed + 300) * -1);
            if(this.score > 24) platform.setVelocityX((gameOptions.platformStartSpeed + 400) * -1);

            this.platformPool.remove(platform);
        } else {
            platform = this.physics.add.sprite(posX, game.config.height * 0.98, "platform");
            platform.setScale(1.4);
            platform.setImmovable(true);
            
            platform.setVelocityX(gameOptions.platformStartSpeed * -1);

            if(this.score > 3) platform.setVelocityX((gameOptions.platformStartSpeed + 100) * -1);
            if(this.score > 7) platform.setVelocityX((gameOptions.platformStartSpeed + 200) * -1);
            if(this.score > 12) platform.setVelocityX((gameOptions.platformStartSpeed + 300) * -1);
            if(this.score > 24) platform.setVelocityX((gameOptions.platformStartSpeed + 400) * -1);

            this.platformGroup.add(platform);
        }
        platform.displayWidth = platformWidth;
        this.nextPlatformDistance = Phaser.Math.Between(gameOptions.spawnRange[0], gameOptions.spawnRange[1]);
    }

    // add kinders (score points)
    addKinder(posX){
        let xmasItem;
        if(this.kinderPool.getLength()){
            xmasItem = this.kinderPool.getFirst();
            xmasItem.x = posX;
            xmasItem.active = true;
            xmasItem.visible = true;

            if(this.score > 3) xmasItem.setVelocityX((gameOptions.platformStartSpeed + 100) * -1);
            if(this.score > 7) xmasItem.setVelocityX((gameOptions.platformStartSpeed + 200) * -1);

            this.kinderPool.remove(xmasItem);
        } else{
            xmasItem = this.physics.add.sprite(posX, game.config.height * gameOptions.kinderVerticalRange[Phaser.Math.Between(0,4)], this.xmasItemsList[Phaser.Math.Between(0,2)]);
            xmasItem.setScale(0.3);
            xmasItem.setImmovable(true);
            xmasItem.setVelocityX(gameOptions.platformStartSpeed * -1);

            if(this.score > 3) xmasItem.setVelocityX((gameOptions.platformStartSpeed + 100) * -1);
            if(this.score > 7) xmasItem.setVelocityX((gameOptions.platformStartSpeed + 200) * -1);

            this.kinderGroup.add(xmasItem);
        }
        this.nextKinderDistance = Phaser.Math.Between(gameOptions.kinderSpawnRange[0], gameOptions.kinderSpawnRange[1]);
    }
 
    // the player jumps when on the ground, or once in the air as long as there are jumps left and the first jump was on the ground
    jump(){
        if(this.player.body.touching.down || (this.playerJumps > 0 && this.playerJumps < gameOptions.jumps)){
            if(this.player.body.touching.down){
                this.playerJumps = 0;
            }
            this.player.setVelocityY(gameOptions.jumpForce * -1);
            this.playerJumps++;
        }
    }

    update(time, delta){

        // animate player texture (walk & jump)
        this.timer += delta;
        while (this.timer > 200){
            this.resources += 1;
            this.timer -= 200;
            if(this.player.body.touching.down){
                this.playerTexture = this.playerTexture == 'playerA' ? 'playerB' : 'playerA';
            } else {
                this.playerTexture = 'playerJump';
            }
            this.player.setTexture(this.playerTexture);
        }
 
        // game over
        if(this.player.y > game.config.height){
            
            this.lives = this.lives - 1
            this.registry.events.emit('update-score', { lives: this.lives, score: this.score, msg: this.i18n[this.lang].ohno, gameover: this.lives > 0 ? false : true });

            if(this.lives > 0){
                this.scene.start("PlayScene", { lives: this.lives, score: this.score });
            }
        }

        this.player.x = gameOptions.playerStartPosition;
 
        // recycling platforms
        let minDistance = game.config.width;
        this.platformGroup.getChildren().forEach(function(platform){
            let platformDistance = game.config.width - platform.x - platform.displayWidth / 2;
            minDistance = Math.min(minDistance, platformDistance);
            if(platform.x < - platform.displayWidth / 2){
                this.platformGroup.killAndHide(platform);
                this.platformGroup.remove(platform);
            }
        }, this);
 
        // adding new platforms
        if(minDistance > this.nextPlatformDistance){
            var nextPlatformWidth = Phaser.Math.Between(gameOptions.platformSizeRange[0], gameOptions.platformSizeRange[1]);
            this.addPlatform(nextPlatformWidth, game.config.width + nextPlatformWidth / 2);
        }

        // recycling kinders
        this.kinderGroup.getChildren().forEach(function(platform){
            let platformDistance = game.config.width - platform.x - platform.displayWidth / 2;
            minDistance = Math.min(minDistance, platformDistance);
            if(platform.x < - platform.displayWidth / 2){
                this.kinderGroup.killAndHide(platform);
                this.kinderGroup.remove(platform);
            }
        }, this);

        // adding new kinders
        if(minDistance > this.nextKinderDistance){
            var nextKinderWidth = Phaser.Math.Between(gameOptions.platformSizeRange[0], gameOptions.platformSizeRange[1]);
            this.addKinder(game.config.width + nextKinderWidth / 2);
        }
    }
}